<template>
  <check-authorization :requiresAuthorizations="['generar proyeccion']">
    <!-- Modal para mostrar el desglose de los importes por mes -->
    <x-modal v-model="showModal" title="Editar importes" @hide="reloadTable" with-filter>
      <template #default="{ filter }">
        <proyeccion-desglose-list
          ref="proyeccionDesgloseList"
          :update-data="updateData"
          :filter="filter"
          @on-updated="editarLayoutWasUpdated = true"
        />
      </template>

      <template #modal-footer="{ close }">
        <b-button size="sm" class="mr-auto" variant="black" @click="close">
          Cerrar
        </b-button>

        <b-button size="sm" variant="primary" @click="createNewImport(1)">
          Crear nuevo importe...
        </b-button>
      </template>
    </x-modal>
    <!-- END: Modal para mostrar el desglose de los importes por mes -->

    <!-- Modal para la creación de un importe -->
    <b-modal
      v-model="showModalForCreateImport"
      title="Crear importe"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <template #default="{cancel}">
        <x-periodo-fiscal-tag class="mb-1" />
        <proyeccion-importe-form
          :periodo_fiscal_id="periodo_fiscal_id"
          :fuente_financiamiento_id="fuente_financiamiento_id"
          :mes="createImportData.mes"
          :prestacion_id="createImportData.prestacion_id"
          @on-created="onCreatedNewImport()"
        >
          <template #btn-cancel-place>
            <b-button variant="black" @click="cancel()" size="sm">
              Cancelar
            </b-button>
          </template>
        </proyeccion-importe-form>
      </template>
    </b-modal>
    <!-- END: Modal para la creación de un importe -->

    <!-- Notificacion de alerta -->
    <portal-target name="notificationAlertProyeccionTable" />

    <loading v-if="tableLoading || separacionImportes.loading" class="py-10"></loading>
    <b-card v-else-if="getItemsForProyeccion.length > 0" no-body class="card-header-actions">
      <b-card-header>
        Planeación
        <b-button
          size="sm"
          variant="danger"
          @click="onSeparacionImportes"
          :disabled="separacionImportes.loading"
        >
          {{ separacionImportes.loading ? 'Espere...' : 'Iniciar separación de importes' }}
        </b-button>
      </b-card-header>

      <b-card-body class="p-1 px-0">
        <alert-with-errors
          title="Separación de importes"
          :error="separacionImportes.error"
          :message="separacionImportes.message"
          :errors="separacionImportes.data.errors"
        />

        <b-table
          :fields="getFieldsForProyeccion"
          :items="getItemsForProyeccion"
          responsive
          small
          hover
          bordered
          sticky-header
          head-variant="light"
          head-row-variant="light"
          style="min-height: 50vh; max-height: 500px;"
          class="my-table"
        >
          <template #cell(partida)="data">
            <div class="d-flex align-items-center my-auto text-center" style="min-height: 80px">
              <b-badge class="w-100">{{ data.value }}</b-badge>
            </div>
          </template>
          <template #cell(concepto)="data">
            <div
              class="d-flex align-items-center text-center overflow-auto bg-blue rounded p-2"
              style="min-height: 80px"
            >
              <strong class="text-white text-center w-100" style="font-size: 0.65rem">
                {{ data.value }}
              </strong>
            </div>
          </template>
          <template #cell()="data">
            <div
              class="d-flex align-items-center justify-content-center my-auto p-0 m-0 rounded text-center"
              style="min-height: 80px; font-size: 1.2rem;"
            >
              <span v-if="typeof data.value === 'string'">
                {{ data.value }}
              </span>
              <b-badge
                variant="dark"
                v-else-if="data.value.prestacion_id === '' && data.value.importe != null"
              >
                {{ $formatNumber(data.value.importe) }}
              </b-badge>
              <a
                href="javascript:void(0)"
                v-else-if="data.value.mes != null"
                @click="onUpdateImport(data.value)"
              >
                {{ $formatNumber(data.value.importe) }}
              </a>
              <span v-else>
                <b-badge variant="danger">
                  {{ $formatNumber(data.value.importe) }}
                </b-badge>
              </span>
            </div>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
  </check-authorization>
</template>

<script>
import { mapGetters } from 'vuex'
import ProyeccionImporteForm from '@/components/Planeacion/ProyeccionImporteForm'
import ProyeccionDesgloseList from '@/components/Planeacion/ProyeccionDesgloseList'
import AlertWithErrors from '@/components/Shared/AlertWithErrors'

const separacionImportes = () => ({
  error: false,
  loading: false,
  message: '',
  data: {
    errors: {}
  }
})

export default {
  name: 'ProyeccionTable',

  components: {
    ProyeccionImporteForm,
    ProyeccionDesgloseList,
    AlertWithErrors
  },

  async created () {
    this.$store.commit('planeacionModule/setProyeccion', { key: 'response', value: [] })

    this.submit()
  },

  data () {
    return {
      //
      // Id del periodo fiscal
      periodo_fiscal_id: null,

      //
      // Fuente de financiamiento
      fuente_financiamiento_id: null,

      tableLoading: false,

      updateData: null,

      showModal: false,

      //
      // Variable para determinar si el modal
      // para crear un nuevo importe se muestra o no.
      showModalForCreateImport: false,

      //
      // Variable para determinar el nivel en que se encuentra el modal
      // de creación de importe.
      //
      // Si el modal es cero está en el primer nivel.
      // Si es 1, está sobrepuesto sobre el desglose de importes.
      levelCreateImportModal: 0,

      //
      // Objeto para guardar datos relevantes para el componente modal,
      // para crear un nuevo importe.
      createImportData: {
        mes: 0,
        prestacion_id: null
      },

      editarLayoutWasUpdated: false,

      //
      // Contiene la respuesta del proceso cuando
      // se genera la separación de importes,
      // cuando se ejecuta el algoritmo.
      separacionImportes: separacionImportes()
    }
  },

  computed: {
    ...mapGetters('planeacionModule', [
      //
      // Obtiene los datos de las cabeceras para la tabla
      'getFieldsForProyeccion',
      //
      // Regresa los items para la tabla
      'getItemsForProyeccion'
    ])
  },

  methods: {
    async init () {
      //
      // Obtiene el periodo fiscal activo
      const periodo = await this.$getPeriodoFiscalActivo()
      this.periodo_fiscal_id = periodo.id

      //
      // Obtiene la fuente de financiamiento por defecto
      const { error, data, message } = await this.$getFuentePorDefecto()
      this.fuente_financiamiento_id = data.id

      return { error, message }
    },

    async submit () {
      let error, message
      ;({ error, message } = await this.init())

      if (error) {
        return this.$notify({ error, message }, 'Error al obtener la fuente de financiamiento')
      }

      this.tableLoading = true
      ;({ error, message } = await this.$store.dispatch('planeacionModule/getProyeccionPeriodo', {
        periodo_fiscal_id: this.periodo_fiscal_id,
        fuente_financiamiento_id: this.fuente_financiamiento_id
      }))
      this.tableLoading = false

      if (error) {
        this.$alert(
          { error, message },
          'Obtener tabla de proyección',
          'notificationAlertProyeccionTable',
          {
            closeOnTimeout: false
          }
        )
      }
    },

    /**
     * Inicia el algoritmo para la separación de importes,
     * entre recursos estatales e ingresos propios.
     */
    onSeparacionImportes () {
      this.$bvModal
        .msgBoxConfirm(
          'Al ejecutar el algoritmo de separación, ya no podrás hacer cambios en la proyección a menos que los sobreescribas.',
          {
            title: '¿Deseas continuar?',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'SÍ',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true
          }
        )
        .then(async value => {
          if (!value) return

          this.separacionImportes = separacionImportes()

          this.separacionImportes.loading = true
          const getval = await this.$store.dispatch('planeacionModule/generarAnalisis', {
            periodo_fiscal_id: this.periodo_fiscal_id,
            fuente_financiamiento_id: this.fuente_financiamiento_id
          })
          this.separacionImportes.loading = false

          this.$alert(getval, 'Separación de importes', 'notificationAlertProyeccionTable', {
            closeOnTimeout: false
          })

          if (getval.error) this.separacionImportes = getval
        })
    },

    reloadTable () {
      if (this.editarLayoutWasUpdated) this.submit()
    },

    onUpdateImport (item) {
      this.saveDataForNewPrestacion(item)

      if (item.importe === 0) return this.createNewImport(0)

      this.updateData = item

      this.showModal = !this.showModal

      this.editarLayoutWasUpdated = false
    },

    saveDataForNewPrestacion (item) {
      this.createImportData.mes = item.mes
      this.createImportData.prestacion_id = item.prestacion_id
    },

    createNewImport (level) {
      this.levelCreateImportModal = level

      this.showModalForCreateImport = true
    },

    onCreatedNewImport () {
      this.showModalForCreateImport = false
      this.editarLayoutWasUpdated = true

      switch (this.levelCreateImportModal) {
        case 0:
          this.submit()
          break

        case 1:
          // TODO: CHECK BEHAVIOR!
          this.$refs.proyeccionDesgloseList.getProyeccionPeriodo()
          break

        default:
          break
      }
    }
  }
}
</script>

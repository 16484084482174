<template>
  <base-view :title="`Planeación - ${title}`">
    <template #right-place>
      <div class="row align-items-center">
        <div class="col col-8 d-flex justify-content-end">
          <check-authorization :requiresAuthorizations="['acceso periodos fiscales']">
            <router-link :to="{ name: 'PeriodosFiscales' }">
              <b-button size="sm">
                Cambiar periodo fiscal activo
              </b-button>
            </router-link>
          </check-authorization>
        </div>
        <div class="col">
          <x-periodo-fiscal-tag classBody="col-4 offset-8 right-content" variant="primary" />
        </div>
      </div>
    </template>

    <nav class="my-nav nav nav-borders">
      <router-link v-for="it in menu" :key="it.id" :to="it.path" class="nav-link ml-0">
        {{ it.meta.title }}
      </router-link>
    </nav>

    <hr class="mt-0 mb-4" />

    <component :is="tab" />
  </base-view>
</template>

<script>
import { capituloMilViews as menu } from '@/router/routes'
import importacion from '@/components/Planeacion/PlaneacionImportacionForm'
import proyeccion from '@/components/Planeacion/ProyeccionTable'
import analisis from '@/components/Planeacion/AnalisisTable'

export default {
  name: 'PlaneacionView',

  components: {
    importacion,
    proyeccion,
    analisis
  },

  props: {
    title: {
      type: String,
      default: ''
    },

    tab: {
      type: String,
      default: 'importacion'
    }
  },

  mounted () {
    this.$store.commit('UIModule/setSidebarActive', false)
  },

  data () {
    return {
      menu
    }
  }
}
</script>
